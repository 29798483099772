<template>
  <div class="instructions__menus-container">
    <div class="container__title">帮助与反馈</div>
    <div class="container__menus__main-body">
      <ul class="menus__wrapper">
        <li
          v-for="(item, index) in buttons"
          :key="index"
          :class="{
            placeholder: item.type === 'br'
          }"
          class="menu__item"
        >
          <router-link
            v-if="item.type === 'click'"
            :to="{
              path: item.link
            }"
            class="item__link"
          >
            {{ item.txt }}
            <svg-icon icon-class="icon-right" class="item__icon" color="#A7A8AB"></svg-icon>
          </router-link>
          <i v-else></i>
        </li>
      </ul>
    </div>
    <router-link
      tag="div"
      class="container__back"
      :to="{
        path: '/home'
      }"
      >返回</router-link
    >
  </div>
</template>

<script>
export default {
  name: 'views-instructions-menus',
  data() {
    return {
      buttons: [
        {
          type: 'click',
          link: '/instructions/briefs',
          txt: '致时光的友人',
        },
        {
          type: 'click',
          link: '/instructions/explain',
          txt: '使用说明',
        },
        {
          type: 'click',
          link: '/instructions/private',
          txt: '隐私政策',
        },
        {
          type: 'br',
          link: '',
          txt: '',
        },
        {
          type: 'click',
          link: '/instructions/feedback',
          txt: '反馈',
        },
      ],
    };
  },
};
</script>

<style></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"instructions__menus-container"},[_c('div',{staticClass:"container__title"},[_vm._v("帮助与反馈")]),_c('div',{staticClass:"container__menus__main-body"},[_c('ul',{staticClass:"menus__wrapper"},_vm._l((_vm.buttons),function(item,index){return _c('li',{key:index,staticClass:"menu__item",class:{
          placeholder: item.type === 'br'
        }},[(item.type === 'click')?_c('router-link',{staticClass:"item__link",attrs:{"to":{
            path: item.link
          }}},[_vm._v(" "+_vm._s(item.txt)+" "),_c('svg-icon',{staticClass:"item__icon",attrs:{"icon-class":"icon-right","color":"#A7A8AB"}})],1):_c('i')],1)}),0)]),_c('router-link',{staticClass:"container__back",attrs:{"tag":"div","to":{
      path: '/home'
    }}},[_vm._v("返回")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }